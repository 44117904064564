import ASR1 from "../Images/ASR1.png";
import ASR2 from "../Images/ASR2.png";
import ASR3 from "../Images/ASR3.png";
import ASR4 from "../Images/ASR4.png";
import ASR5 from "../Images/ASR5.png";
import ASR6 from "../Images/ASR6.png";
import ASR7 from "../Images/ASR7.png";

export const imagesASR = [
  {
    image: ASR1,
    title: "",
  },
  {
    image: ASR2,
    title: "",
  },
  {
    image: ASR3,
    title: "",
  },
  {
    image: ASR4,
    title: "",
  },
  {
    image: ASR5,
    title: "",
  },
  {
    image: ASR6,
    title: "",
  },
  {
    image: ASR7,
    title: "",
  },
];
