import PotentialCoreStudy1 from "../Images/PotentialCoreStudy1.png";
import PotentialCoreStudy2 from "../Images/PotentialCoreStudy2.png";

export const imagesPotentialCoreStudy = [
  {
    image: PotentialCoreStudy1,
    title: "",
  },
  {
    image: PotentialCoreStudy2,
    title: "",
  },
];
