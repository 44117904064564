import SEM from "../Images/SEM1.png";
import wings from "../Images/Mountain3.jpg";
import geotec from "../Images/Mountain4.jpg";
import geotec1 from "../Images/Mountain5.jpg";

export const imagesSEM = [
  {
    image: SEM,
    title: "",
  },
];
