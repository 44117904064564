import RockAndCoreCutting1 from "../Images/RockAndCoreCutting1.png";
import RockAndCoreCutting2 from "../Images/RockAndCoreCutting2.png";

export const imagesRockAndCoreCutting = [
  {
    image: RockAndCoreCutting1,
    title: "",
  },
  {
    image: RockAndCoreCutting2,
    title: "",
  },
];
