import mountain from "../Images/Mountain2.jpg";
import wings from "../Images/Mountain3.jpg";
import geotec from "../Images/Mountain4.jpg";
import geotec1 from "../Images/Mountain5.jpg";

export const images = [
  {
    image: wings,
    title: "",
  },
  {
    image: mountain,
    title: "",
  },
  {
    image: geotec,
    title: "",
  },
  {
    image: geotec1,
    title: "",
  },
];
