import GPS1 from "../Images/GPS1.png";
import GPS2 from "../Images/GPS2.png";
import GPS3 from "../Images/GPS3.png";
import GPS4 from "../Images/GPS4.png";
import GPS5 from "../Images/GPS5.png";
import GPS6 from "../Images/GPS6.png";

export const imagesGeoPhysical = [
  {
    image: GPS1,
    title: "",
  },
  {
    image: GPS2,
    title: "",
  },
  {
    image: GPS3,
    title: "",
  },
  {
    image: GPS4,
    title: "",
  },
  {
    image: GPS5,
    title: "",
  },
  {
    image: GPS6,
    title: "",
  },
];
