import structuralMapping1 from "../Images/StructuralMapping1.png";
import structuralMapping2 from "../Images/StructuralMapping2.png";
import structuralMapping3 from "../Images/StructuralMapping3.png";
import structuralMapping4 from "../Images/StructuralMapping4.png";

export const imagesStructuralMapping = [
  {
    image: structuralMapping1,
    title: "",
  },
  {
    image: structuralMapping2,
    title: "",
  },
  {
    image: structuralMapping3,
    title: "",
  },
  {
    image: structuralMapping4,
    title: "",
  },
];
