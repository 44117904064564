import Petrography1 from "../Images/PetroGraphy1.png";
import Petrography2 from "../Images/PetroGraphy2.png";

export const imagesPetroGraphy = [
  {
    image: Petrography1,
    title: "",
  },
  {
    image: Petrography2,
    title: "",
  },
];
